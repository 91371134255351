
import {
  IonPage,
  IonContent,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../../components/Empty.vue";
import { Analytics } from "../../../../common/analytics";

/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    Empty,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonImg,
  },
  props: {
    // userId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props) {
    const router = useRouter();
    const screenName = `PAGE-USER_${props.id}_STAMP`;
    const analytics = new Analytics();
    const state = reactive({
      stampFillters: [],
      randomNum: Math.floor(Math.random() * 5),
      stamp: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      contentLoading: true,
      isScrollDisabled: false,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.stamp.size,
        page: state.stamp.page,
      };
      await services.stampUserFindone(props.id, params).then(
        (response) => {
          const { data } = response;
          if (event) {
            if (event.type == "ion-refresh") {
              state.stamp.items = [];
            }
          }
          data.items.forEach((item: any) => {
            if (item.acquisition == 0) {
              state.stampFillters = [
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #d4ff6e)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #7ae4ff)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffdf61)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ebb5ff)",
                },
                {
                  filter:
                    "opacity(0.8) grayscale(100%) drop-shadow(0 0 0 #ffc48d)",
                },
              ] as any;
            } else {
              state.stampFillters = [];
            }
          });
          data.items.some((item: object) => {
            state.stamp.items.push(item);
          });

          state.stamp.totalItems = data.totalItems;
          state.stamp.totalPages = data.totalPages;
          state.stamp.loading = false;
          state.stamp.page++;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.stamp.items = [];
            }
          }
          state.stamp.loading = false;
          state.isScrollDisabled = true;
        }
      );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const onStampLink = (id: number) => {
      router.push({
        name: "StampId",
        params: { id: id },
      });
    };

    const onClose = () => {
      window.history.length > 1 ? router.go(-1) : router.push("/");
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.stamp.page = 1;
      state.stamp.loading = true;
      state.isScrollDisabled = false;
      fetch(event);
    };

    // onMounted
    onMounted(() => {
      state.stamp.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return { state, onStampLink, onClose, onLoadData, onRefresh };
  },
});
